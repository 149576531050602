const portalConfig = {

  "api_url": "/api/",
  "fn_path": "fn_submission",
  "fp_path": "fp_submission",
  "url_path": "url_submission",
  "url_query_path": "url_query_submission",
  "check_submission_path": "check_submission",
  "old_version_url": "https://safeweb.norton.com/file_dispute",
  "new_version_url": "https://submissions.norton.com/submitsample",
  "old_url_dispute_link": "https://safeweb.norton.com/submit_dispute"
}

export default portalConfig
