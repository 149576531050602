import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import portalConfig from './portalConfig'

import { withTranslation } from 'react-i18next';

class Banner extends React.Component {
    render() {
        return (
            <div className="App-Banner">
                <div className="choose-product-header">
                    <p className="step-1">{this.props.t("banner.chooseVersion")}</p>                   
                    <p className="step-2">{this.props.t("banner.selectNortonProduct")}</p>
                    <p className="step-3">{this.props.t("banner.aboutProduct")}</p>
                </div>
                <div className="version-banner-section">
                    <div className="version-banner">
                        <div className="banner-1-section">
                            <div className="banner-1">
                                <div className="products-list">
                                    <p>{this.props.t("banner.product-1_1")} </p>
                                    <p>{this.props.t("banner.product-1_2")} </p>
                                    <p>{this.props.t("banner.product-1_3")} </p>
                                </div>
                                <div className="submission-link">
                                    <a href={portalConfig["old_version_url"]} target="_blank" rel="noopener noreferrer">{this.props.t("banner.fileSubmission")}</a>
                                    <a href={portalConfig["old_url_dispute_link"]} target="_blank" rel="noopener noreferrer">{this.props.t("banner.urlSubmission")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="banner-2-section">
                            <div className="banner-2">
                                <div className="products-list">
                                    <p>{this.props.t("banner.product-2_1")}</p>
                                    <p>{this.props.t("banner.product-2_2")}</p>
                                </div>
                                <div className="submission-link">
                                    <a href={portalConfig["new_version_url"]} target="_blank" rel="noopener noreferrer">{this.props.t("banner.urlAndFileSubmission")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        ) 
    }
}

export default withTranslation()(Banner)